import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import React, { memo } from 'react'

export interface Props {
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  pretitle?: string
  title?: string
  index?: number
}

export const Item = memo(function Item({
  cta,
  description,
  image,
  pretitle,
  title,
  index = 0,
}: Props) {
  if (!image) {
    return null
  }

  return (
    <Container>
      <FadeInUp delay={(index % 2) * 0.15}>
          <StyledImage {...image} />
        {pretitle ? (
          <>
            <Line className="short" />
            <Pretitle>{pretitle}</Pretitle>
          </>
        ) : null}
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
        {cta ? <StyledButton {...cta} variant="simple" /> : null}
      </FadeInUp>
    </Container>
  )
})

const Container = styled.div`
  width: calc(50% - 4.16vw);
  margin-left: 4.16vw;
  margin-bottom: 6.66vw;

  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 70px;
  }
`

const StyledImage = styled(Image)`
  position: relative;
  display: block;
  width: 100%;
  height: 26.38vw;
  border-radius: 0.69vw;
  margin-bottom: 2.7vw;
  overflow: hidden;

  /* @media (max-width: 991px) {
    max-width: 100%;
  } */

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 991px) {
    height: 60vw;
    border-radius: 6px;
    margin-bottom: 25px;
  }
`

const Pretitle = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.83vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.69vw;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  @media (max-width: 1439px) {
    font-size: 10px;
    margin-left: 12px;
  }
`

const Title = styled.h3`
  margin-top: 0.83vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 1.38vw;

  @media (max-width: 1439px) {
    font-size: 20px;
  }

  @media (max-width: 991px) {
    margin-top: 20px;
  }
`

const Description = styled.div`
  margin-top: 1.38vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1.04vw;
  line-height: 1.73;

  @media (max-width: 1439px) {
    font-size: 15px;
    line-height: 26px;
  }

  @media (max-width: 991px) {
    margin-top: 20px;
  }
`

const StyledButton = styled(Button)`
  margin-top: 2.5vw;

  @media (max-width: 1439px) {
    margin-top: 20px;
  }
`
